#gform_fields_1 label.gfield_label {
  display: none;
}

.page-id-9145 body.sidebar-primary .main {
  padding-top: 0px !important;
}
#order_comments_field { display:none; }

.information-block .info .new-toys {
  right: 150px;
}

footer p {
  font-size: .9em;
  line-height: 1.2;
  font-weight: 400;
}
.newsletter p {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.35;
  color: #fff;
  text-shadow: 1px 1px #000000, 1px -1px #000000, -1px 1px #000000, -1px -1px #000000;
}

@media screen and (min-width: 1400px) {
  .newsletter p {
    font-size: 1.65em;
  }
}

.inspiration h3 {
  color: #2059c1;
}
.banner.contact-banner .info, .banner.mishpacha-banner .info {
  z-index: 5;
}
@media screen and (min-width: 1025px) {
  .inspiration h3 {
    font-size: 3.6em;
    -webkit-text-stroke: none;
    text-stroke: none;
  }
}

.page-id-200 h3, .page-id-165 h3 {
  text-align: center;
  margin-bottom: 10px;
  margin-top:42px;
}

.page-id-200 p, .page-id-165 p {
  text-align: center;
}

.page-id-200 .site-information ol, .site-information ul, .page-id-165 .site-information ol, .site-information ul {
  padding-left: 100px;
  list-style-position: outside;
}
.page-id-200 .site-information ol li, .site-information ul li, .page-id-165 .site-information ol li, .site-information ul li {
  padding-left: 20px;
  font-size: 1em;
  line-height: 1.2;
  color: #3e4780;
  font-weight: 400;
  margin-bottom: 5px;
}

@media screen and (min-width: 1025px) {
  .page-id-200 h3, .page-id-165 h3 {
    font-size: 2em;
  }
}
@media screen and (max-width: 450px) {
  .page-id-200 .site-information ol, .site-information ul, .page-id-165 .site-information ol, .site-information ul {
    padding-left: 40px;
    list-style-position: outside;
  }
  .page-id-200 .site-information ol li, .site-information ul li, .page-id-165 .site-information ol li, .site-information ul li {
    padding-left: 5px;
  }
  .banner {
    height: 210px;
  }
}

@media screen and (min-width: 768px) {
  .the-press .logos .logo {
    width: 17%;
    max-width: 17%;
    -ms-flex: 0 0 17%;
    flex: 0 0 17%;
    margin: 0 2.5% 40px;
  }
}
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  line-height: 1.4;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
blockquote {
  display: block;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.container {
  max-width: 1270px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

/*-----------------------
    Main
-----------------------*/
body {
  padding-top: 145px;
}

@media screen and (min-width: 1025px) {
  body {
    padding-top: 172px;
  }
}

/*-----------------------
    Typography
-----------------------*/
h1 {
  font-size: 2.4em;
  line-height: 1.2;
  margin: 0 0 60px 0;
  color: #2059c1;
  font-family: 'Merienda One', cursive;
}

h2 {
  font-size: 2em;
  line-height: 1.2;
  margin: 0 0 20px 0;
  font-family: 'Merienda One', cursive;
}

h3 {
  font-size: 1.9em;
  line-height: 1.2;
  margin: 0 0 20px 0;
  color: #2059c1;
  font-family: 'Merienda One', cursive;
}

p {
  font-size: 1em;
  line-height: 2;
  color: #3e4780;
  margin: 0 0 25px 0;
}

p.highlight {
  font-size: 1.1em;
  font-weight: 700;
}

.decorated {
  background: url('../images/heading-decoration.png') no-repeat 50% 50%;
}

@media screen and (min-width: 1025px) {
  h1 {
    font-size: 3.25em;
  }

  h3 {
    font-size: 3em;
  }

  .decorated {
    line-height: 98px;
    position: relative;
    display: inline-block;
    padding: 0 20px;
  }

  .decorated::after {
    content: url('../images/spinner.png');
    position: absolute;
    left: 100%;
    top: -20px;
  }
}

/*-----------------------
    Header
-----------------------*/
header {
  background-color: #004c98;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}

header .main-navigation {
  padding: 12px 0 12px 0;
}

header .logo {
  display: inline-block;
  width: 100px;
}

header .logo img {
  display: block;
  width: 100%;
  height: auto;
}

header .toggle-navigation {
  display: inline-block;
  border: none;
  background-color: transparent;
  padding: 0;
  width: 45px;
  position: relative;
  float: right;
  top: 25px;
  cursor: pointer;
  z-index: 120;
}

header .toggle-navigation .line {
  display: block;
  width: 100%;
  height: 5px;
  background-color: white;
  transition: opacity 250ms linear, transform 250ms linear;
}

header .toggle-navigation .line + .line {
  margin-top: 5px;
}

header .toggle-navigation .line:nth-child(3) {
  position: absolute;
  left: 0;
  top: 10px;
  margin-top: 0;
}

.navigation-open .toggle-navigation .line:nth-child(1),
.navigation-open .toggle-navigation .line:nth-child(4) {
  opacity: 0;
}

.navigation-open .toggle-navigation .line:nth-child(2) {
  transform: rotate(45deg);
}

.navigation-open .toggle-navigation .line:nth-child(3) {
  transform: rotate(-45deg);
}

header nav {
  position: fixed;
  width: 270px;
  height: 100%;
  overflow-y: auto;
  padding: 15px;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: #004c98;
  color: white;
  transform: translateX(-100%);
  transition: transform 300ms linear;
}

.navigation-open header nav {
  transform: translateX(0%);
}

header nav a {
  font-size: 1em;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.25px;
}

header nav .social {
  margin: 15px 0;
}

header nav .social a {
  width: 22px;
  height: 22px;
  line-height: 23px;
  margin: 0 2px;
  font-size: 1em;
}

header nav .navbar a {
  font-weight: 600;
}

header nav .navbar .menu-item-has-children > a {
  display: inline-block;
  margin-bottom: 10px;
}

header nav .navbar .sub-menu {
  padding-left: 30px;
}

header nav .navbar li {
  margin-bottom: 10px;
}

@media screen and (min-width: 1025px) {
  header {
    position: fixed;
  }

  header .selling-information {
    height: 46px;
    transition: height 300ms linear, opacity 300ms linear;
  }

  header.selling-obscured .selling-information {
    height: 0;
    opacity: 0;
  }

  header .toggle-navigation {
    display: none;
  }

  header .logo {
    width: 140px;
  }

  header nav {
    position: static;
    overflow-y: visible;
    width: auto;
    height: auto;
    transform: none;
    padding: 10px 0 0 0;
    left: auto;
    top: auto;
    text-align: right;
    float: right;
  }

  header nav .navbar {
    margin-top: 30px;
  }

  header nav .navbar li {
    display: inline-block;
    vertical-align: bottom;
    margin: 0 10px;
  }

  header nav .navbar li:last-child {
    margin-right: 0;
  }

  header nav .navbar a {
    display: inline-block;
    padding-bottom: 5px;
  }

  header nav .navbar a:hover {
    color: #e0c500;
  }

  header nav .navbar .menu-item-has-children {
    position: relative;
  }

  header nav .navbar .menu-item-has-children > a {
    margin-bottom: 0;
  }

  header nav .navbar .sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    padding: 15px;
    background-color: white;
    text-align: left;
    border-top: 3px solid black;
    z-index: 50;
  }

  header .navbar .sub-menu a {
    color: #004c98;
  }

  header .navbar .sub-menu a:hover {
    color: #004c98;
    text-decoration: underline;
  }

  header .navbar .sub-menu li {
    margin-bottom: 10px;
  }

  header .navbar .sub-menu li:last-child {
    margin-bottom: 0;
  }

  header nav .navbar .menu-item-has-children:hover .sub-menu {
    display: block;
  }

  header nav .social {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 25px;
  }
}

/*-----------------------
    Selling Information
-----------------------*/
.selling-information {
  font-size: 1em;
  line-height: 1.2;
  background-color: #e0c500;
  color: #3e4781;
  text-align: center;
}

.selling-information .units-data {
  padding: 7px 0;
}

.selling-information p {
  margin: 0;
  font-weight: 700;
  font-family: 'Merienda One', cursive;
}

/*-----------------------
    Hero
-----------------------*/
.hero {
  height: 350px;
  background: url('../images/blue-stripes.png') repeat #2b62c5;
  position: relative;
}

.hero::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(43, 98, 197, 0.95));
  z-index: 10;
}

.hero .info {
  height: 350px;
}

.hero .info img {
  display: block;
  max-width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 20;
}

@media screen and (min-width: 768px) {
  .hero {
    height: auto;
  }

  .hero .info {
    height: auto;
    padding-top: 25px;
  }

  .hero .info img {
    position: relative;
    left: auto;
    bottom: auto;
  }
}

/*-----------------------
    Banner
-----------------------*/
.banner {
  height: 330px;
  position: relative;
  background-repeat: repeat;
  background-position: 50% 50%;
  overflow: hidden;
  position: relative;
}

.banner.mensch-banner {
  background-image: url('../images/the-mensch-banner.jpg');
}

.banner.mishpacha-banner,
.banner.shop-banner,
.banner.contact-banner,
.banner.mensch-day-banner,
.banner.information-banner {
  background-image: url('../images/blue-stripes.png');
}

.banner.gallery-banner {
  background-image: url('../images/gallery-banner.jpg');
  background-size: cover;
}

.banner.press-banner {
  background-image: url('../images/press-background.jpg');
  background-size: cover;
}

.banner.mishpacha-banner::before,
.banner.shop-banner::before,
.banner.contact-banner::before,
.banner.mensch-day-banner::before,
.banner.information-banner::before,
.banner.press-banner::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.banner.mishpacha-banner::before {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 0.55));
}

.banner.shop-banner::before,
.banner.contact-banner::before,
.banner.mensch-day-banner::before,
.banner.information-banner::before,
.banner.press-banner::before {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, rgba(22, 88, 155, 0.8));
}

.banner .info {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 15px 15px 25px 15px;
  text-align: center;
  z-index: 20;
}

.banner.mishpacha-banner .info,
.banner.contact-banner .info {
  z-index: 4;
}

.banner h2 {
  color: white;
  -webkit-text-stroke: 1px #215ac2;
  text-stroke: 1px #215ac2;
}

.banner.information-banner h2 {
  -webkit-text-stroke: unset;
}

.banner .character {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  z-index: 3;
}

@media screen and (min-width: 1025px) {
  .banner {
    height: 600px;
  }

  .banner.information-banner {
    height: 315px;
  }

  .banner h2 {
    font-size: 4em;
  }

  .banner.information-banner h2 {
    font-size: 3em;
    margin: 0;
  }

  .banner.information-banner .info {
    bottom: 50%;
    transform: translateY(50%);
  }
}

@media screen and (min-width: 1600px), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .banner.gallery-banner {
    background-image: url('../images/gallery-banner-lg.jpg');
  }
}

/*-----------------------
    Intro
-----------------------*/
.intro {
  padding: 90px 0 100px 0;
  text-align: center;
  background: url('../images/waves-background.png') repeat;
}

.intro p {
  font-weight: 500;
  margin-bottom: 70px;
}

@media screen and (min-width: 1025px) {
  .intro {
    overflow: hidden;
  }

  .intro h1 {
    line-height: 98px;
  }

  .intro .button.primary {
    font-size: 1.7em;
    border-width: 5px;
  }
}

/*-----------------------
    Information Block
-----------------------*/
.information-block {
  margin-bottom: 30px;
}

.information-block h2 img {
  display: block;
  max-width: 100%;
  height: auto;
}

.information-block .artwork {
  display: block;
  width: 100%;
  height: auto;
}

.information-block .info {
  padding: 30px 0 30px 0;
  text-align: center;
  position: relative;
}

.information-block .info p {
  color: white;
  line-height: 1.36;
}

.information-block.blue .info {
  background-color: #215ac2;
}

.information-block.blue h2 {
  color: #deb025;
  -webkit-text-stroke: 1px #004c98;
  text-stroke: 1px #004c98;
}

.information-block.black .info {
  background-color: black;
}

.information-block.black h2 {
  color: #ddaf37;
}

.information-block.orange .info {
  background-color: #deb025;
  background: url('../images/orange-stripes-pattern.jpg') repeat;
}

.information-block.orange h2 {
  color: #2059c1;
}

.information-block .button.primary {
  background-color: white;
}

.information-block .info .new-toys {
  display: none;
  position: absolute;
  top: -85px;
  right: 30px;
  width: 200px;
  height: auto;
}

@media screen and (min-width: 1025px) {
  .information-block.blue h2 {
    font-size: 2.6em;
    display: inline-block;
    padding: 5px;
    margin-bottom: 5px;
    position: relative;
    z-index: 10;
  }

  .information-block.blue h2::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(ellipse at 50% 50%, rgba(255, 255, 255, .2), rgba(255, 255, 255, .2) 40%, rgba(255, 255, 255, 0) 70%);
    z-index: -2;
  }

  .information-block .info p {
    font-size: 1.375em;
    max-width: 730px;
    margin: 0 auto 20px auto;
  }

  .information-block .info .new-toys {
    display: inline-block;
  }
}

/*-----------------------
    Instagram Feed
-----------------------*/
.instagram-feed {
  background: url(../images/blue-stripes.png) repeat #2b62c5;
  position: relative;
  padding: 20px 0;
}

.instagram-feed::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(33, 90, 194, 1));
  z-index: 10;
}

.instagram-feed .info {
  position: relative;
  z-index: 20;
}

.instagram-feed .ideas {
  margin-bottom: 40px;
}

.instagram-feed .ideas img {
  display: block;
  max-width: 100%;
  height: auto;
}

.instagram-feed .feeds .feed {
  margin-bottom: 20px;
}

.instagram-feed .feeds .feed a {
  display: block;
  border: 8px solid white;
  border-top-width: 5px;
  border-bottom-width: 10px;
}

.instagram-feed .feeds .feed img {
  display: block;
  width: 100%;
  height: auto;
}

@media screen and (min-width: 768px) {
  .instagram-feed .feeds {
    display: flex;
    justify-content: space-between;
  }

  .instagram-feed .feeds .feed {
    width: calc(33.3333% - 20px);
    flex: 0 0 calc(33.3333% - 20px);;
  }
}

@media screen and (min-width: 1025px) {
  .instagram-feed .feeds {
    width: 870px;
  }
}

@media screen and (min-width: 1200px) {
  .instagram-feed {
    padding-bottom: 0;
  }

  .instagram-feed .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .instagram-feed .info .ideas {
    width: 340px;
    flex: 0 1 340px;
    margin-bottom: 0;
  }

  .instagram-feed .info .feeds {
    width: 800px;
    flex: 0 0 800px;
  }
}

@media screen and (min-width: 1400px) {
  .instagram-feed .info .feeds {
    width: 830px;
    flex: 0 0 830px;
  }
}

/*-----------------------
    Share Your Idea
-----------------------*/
.share-your-idea {
  display: inline-block;
  padding: 15px 13px;
  text-align: center;
  border: 5px solid #e0c500;
  background-color: white;
  font-size: 1.1em;
  line-height: 1.2;
  font-weight: 700;
  color: #215ac2;
  margin-bottom: 20px;
}

.share-your-idea .tag {
  display: block;
  font-family: 'Merienda One', cursive;
}

@media screen and (min-width: 768px) {
  .share-your-idea {
    padding: 15px 20px;
  }

  .share-your-idea .tag {
    font-size: 1.7em;
  }
}

/*-----------------------
    Newsletter
-----------------------*/
.newsletter {
  padding: 60px 0 45px 0;
  text-align: center;
  background: url('../images/brick-pattern.jpg') #dfb63e;
}

.newsletter p {
  font-size: 1.2em;
  line-height: 1.25;
  color: white;
  text-shadow: 1px 1px #215ac2, 1px -1px #215ac2, -1px 1px #215ac2, -1px -1px #215ac2;
}

.newsletter .form {
  max-width: 540px;
  margin: 0 auto;
}

.newsletter .form .gfield {
  margin-bottom: 20px;
}

.newsletter .form input[type="text"],
.newsletter .form input[type="email"] {
  display: block;
  width: 100%;
  border: 2px solid #215ac2;
  background-color: white;
  color: black;
  padding: 15px;
  font-size: 1.2em;
  text-align: center;
}

.newsletter .form input[type="email"]::-webkit-input-placeholder {
  color: black;
}

.newsletter .form input[type="email"]::-moz-placeholder {
  color: black;
}

.newsletter .form input[type="email"]:-ms-input-placeholder {
  color: black;
}

.newsletter .form .button {
  border-color: #215ac2;
  background-color: #215ac2;
  color: white;
}

@media screen and (min-width: 1400px) {
  .newsletter p {
    font-size: 1.5em;
  }
}

/*-----------------------
    Foundation
-----------------------*/
.foundation {
  padding: 40px 0;
  text-align: center;
  background: url('../images/waves-background.png') repeat;
}

.foundation h3 {
  color: #deb025;
}

.foundation p:not(.highlight) {
  font-weight: 500;
  margin-bottom: 40px;
}

.foundation .rules {
  margin-bottom: 60px;
}

.foundation .rules .button {
  text-transform: none;
}

@media screen and (min-width: 1025px) {
  .foundation {
    padding: 80px 0 60px 0;
  }

  .foundation h3 {
    font-size: 3.6em;
    margin-bottom: 50px;
  }

  .foundation .shop-now .sold {
    position: absolute;
    right: -130px;
    bottom: 15px;
  }
}

/*-----------------------
    Shop Now
-----------------------*/
.shop-now {
  position: relative;
  max-width: 290px;
  margin: 0 auto;
}

.shop-now .toys {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.shop-now .button {
  width: 100%;
  max-width: 270px;
}

.shop-now .hand {
  position: absolute;
  left: 15px;
  top: 154px;
}

.shop-now .sold {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 15px 0 0 0;
}

@media screen and (min-width: 1025px) {
  .shop-now .sold {
    position: absolute;
    right: -130px;
    bottom: 15px;
  }
}

/*-----------------------
    About The Author
-----------------------*/
.about-author {
  padding: 40px 0;
  text-align: center;
}

.about-author h3 {
  margin-bottom: 60px;
}

.about-author img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto 70px auto;
}

.about-author p:not(.highlight) {
  font-weight: 500;
  margin-bottom: 40px;
}

@media screen and (min-width: 1025px) {
  .about-author {
    padding: 75px 0 120px;
  }
}

/*-----------------------
    Portrait
-----------------------*/
.portrait .term {
  padding: 40px 0;
  background: url('../images/waves-background.png');
}

.portrait .character {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto 15px auto;
}

.portrait .term .info {
  color: #004c98;
  line-height: 1.4;
}

.portrait .term .info span {
  font-family: 'Merienda One', cursive;
  font-size: 2.5em;
}

.portrait .details {
  padding: 50px 0;
  text-align: center;
  background-color: #215ac2;
}

.portrait .details p {
  color: white;
  font-weight: 600;
  line-height: 1.7;
  margin-bottom: 50px;
}

.portrait .details .button {
  text-transform: none;
  font-weight: 600;
}

@media screen and (min-width: 1025px) {
  .portrait .term .info {
    font-size: 1.2em;
  }

  .portrait .container {
    position: relative;
    max-width: 1070px;
  }

  .portrait .character {
    margin: 0 0 -170px 0;;
  }

  .portrait .info {
    position: absolute;
    right: 120px;
    bottom: 280px;
  }

  .portrait .details {
    padding: 140px 0 70px 0;
  }

  .portrait .details p {
    font-size: 1.25em;
  }
}

/*-----------------------
    Meet The Company
-----------------------*/
.meet-the-company {
  padding: 50px 0;
  text-align: center;
}

.meet-the-company h3 {
  text-align: center;
  color: #2059c1;
  background: url('../images/wave-decoration.png') no-repeat 50% 50%;
  /*-webkit-text-stroke: 1px #e8d33f;
  text-stroke: 1px #e8d33f;*/
}

.meet-the-company .characters .character {
  margin-bottom: 60px;
}

.meet-the-company .characters .image img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto 15px auto;
}

.meet-the-company .characters h5 {
  font-size: 2.1em;
  line-height: 1.2;
  color: #3e4781;
  font-family: 'Merienda One', cursive;
  margin: 0 0 20px 0
}

.meet-the-company .characters p {
  font-weight: 500;
}

.meet-the-company .things-to-do {
  margin-bottom: 80px;
}

.meet-the-company .things-to-do p {
  font-size: 1.1875em;
  line-height: 1.7;
  font-weight: 600;
}

.meet-the-company .things-to-do h3 {
  margin-bottom: 40px;
}

@media screen and (min-width: 1025px) {
  .meet-the-company {
    padding: 100px 0 80px 0;
  }

  .meet-the-company h3 {
    position: relative;
    display: inline-block;
    padding: 0 20px;
    margin-bottom: 100px;
  }

  .meet-the-company h3::after {
    content: url(../images/spinner.png);
    position: absolute;
    left: 100%;
    top: -25px;
  }

  .meet-the-company .things-to-do {
    margin-bottom: 120px;
  }

  .meet-the-company .things-to-do h3 {
    margin-bottom: 30px;
  }

  .meet-the-company .things-to-do h3::after {
    top: -35px;
  }

  .meet-the-company h3 {
    padding: 10px 0;
    font-size: 2.8em;
  }

  .meet-the-company .characters {
    max-width: 950px;
    margin: 0 auto 150px auto;
  }

  .meet-the-company .characters .character {
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .meet-the-company .character .image {
    width: 280px;
    flex: 0 0 280px;
    margin-right: 40px;
  }

  .meet-the-company .character .info {
    width: 550px;
    flex: 1 1 550px;
    align-self: flex-start;
    padding-top: 60px;
  }

  .meet-the-company .characters .image img {
    margin: 0 auto;
  }
}

/*-----------------------
    Inspiration
-----------------------*/
.inspiration {
  padding: 70px 0 60px 0;
  text-align: center;
  background: url(../images/waves-background.png) repeat;
  overflow: hidden;
}

.inspiration h3 {
  color: #deb025;
}

.inspiration .ideas {
  display: block;
  font-size: 1.25em;
  font-style: italic;
  color: #3e4781;
  font-weight: 500;
  margin-bottom: 35px;
}

.inspiration p {
  font-weight: 500;
  margin-bottom: 100px;
}

.inspiration h4 {
  font-size: 2em;
  color: #215ac2;
  margin: 0 0 15px 0;
  font-family: 'Merienda One', cursive;
}

.inspiration h4 + p {
  margin-bottom: 30px;
}

.inspiration .inspiration-gallery  {
  margin-bottom: 70px;
}

.inspiration .gallery-item img {
  display: block;
  width: 100%;
  height: auto;
  transition: transform 250ms linear;
}

.inspiration .inspiration-gallery .slide-arrow {
  display: inline-block;
  width: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 20;
}

.inspiration .slide-arrow img {
  display: block;
  width: 100%;
  height: auto;
}

.inspiration .inspiration-gallery .slide-arrow.slide-prev {
  left: 15px;
}

.inspiration .inspiration-gallery .slide-arrow.slide-next {
  right: 15px;
}

@media screen and (min-width: 1025px) {
  .inspiration h3 {
    font-size: 3.6em;
    -webkit-text-stroke: 1px #2059c1;
    text-stroke: 1px #2059c1;
  }

  .inspiration h4 {
    font-size: 3em;
  }

  .inspiration .gallery-item  {
    padding: 60px 10px;
    /*-webkit-transform: translate3d(0, 0, 0);*/
  }

  .inspiration .gallery-item img {
    box-shadow: 10px 10px 0 0 rgba(0, 0, 0, .3);
  }

  .inspiration .inspiration-gallery .slide-arrow {
    width: 67px;
  }

  .inspiration .gallery-item.slick-current img {
    transform: scale(1.2);
  }
}

@media screen and (min-width: 1400px) {
  .inspiration .inspiration-gallery .slide-arrow.slide-prev {
    left: -100px;
  }

  .inspiration .inspiration-gallery .slide-arrow.slide-next {
    right: -100px;
  }
}

/*-----------------------
    Wall of Fame
-----------------------*/
.wall-of-fame {
  padding: 40px 0 60px 0;
  text-align: center;
}

.wall-of-fame .amblem {
  display: inline-block;
  max-width: 100%;
  height: auto;
  margin-bottom: 25px;
}

.wall-of-fame .fame-gallery .item {
  display: block;
  width: 100%;
  max-width: 325px;
  margin: 0 auto 30px auto;
}

.wall-of-fame .fame-gallery .item img {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 5px;
}

.wall-of-fame .fame-gallery p {
  margin: 0;
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  .wall-of-fame .fame-gallery {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .wall-of-fame .fame-gallery .item {
    width: 48%;
    max-width: 100%;
    flex: 0 0 48%;
    margin: 0 0 30px 0;
  }
}

@media screen and (min-width: 1025px) {
  .wall-of-fame .fame-gallery {
    max-width: 1050px;
    margin: 0 auto 50px auto;
  }

  .wall-of-fame .fame-gallery .item {
    width: calc(33.3333% - 20px);
    flex: 0 0 calc(33.3333% - 20px);
  }
}

/*-----------------------
    Become A Mensch
-----------------------*/
.become-a-mensch {
  padding: 60px 0 50px 0;
  background: url(../images/waves-background.png);
  text-align: center;
}

.become-a-mensch h3 {
  color: #deb025;
  margin: 0 0 20px 0;
}

.become-a-mensch .date {
  font-family: 'Merienda One', cursive;
  font-size: 1.5em;
  line-height: 1.1;
  margin: 0 0 20px 0;
  color: #215ac2;
}

.become-a-mensch p {
  font-size: 1.1875em;
  line-height: 1.68;
  font-weight: 500;
  margin: 0 0 60px 0;
}

.become-a-mensch h4 {
  font-family: 'Merienda One', cursive;
  font-size: 1.8em;
  color: #215ac2;
  margin: 0 0 20px 0;
}

@media screen and (min-width: 1025px) {
  .become-a-mensch .date {
    font-size: 1.75em;
  }

  .become-a-mensch h4 {
    font-size: 2.7em;
  }
}

/*-----------------------
    Site Information
-----------------------*/
.site-information {
  padding: 40px 0;
  /*background: url(../images/waves-background.png);*/
}

.site-information h2 {
  color: #2059c1;
}

.site-information h3 {
  margin-bottom: 40px;
  color: #2059c1;
}

.site-information ul {
  list-style-type: disc;
}

.site-information ul,
.site-information ol {
  padding-left: 10px;
  list-style-position: inside;
  margin-bottom: 30px;
}

.site-information ul li,
.site-information ol li {
  font-size: 1em;
  line-height: 1.2;
  color: #3e4780;
  font-weight: 600;
  margin-bottom: 5px;
}

.site-information a {
  color: #2059c1;
  font-weight: 600;
  text-decoration: underline;

}

/*-----------------------
    Press
-----------------------*/
.the-press {
  padding: 30px 0 30px 0;
  text-align: center;
}

.the-press h2 {
  color: #215ac2;
  margin: 0 0 15px 0;
}

.the-press .featured {
  color: #deb025;
  font-family: 'Merienda One', cursive;
}

.the-press .logos {
  margin-bottom: 60px;
}

.the-press .logos .logo {
  display: inline-block;
  max-width: 250px;
  margin-bottom: 20px;
}

.the-press .logos .logo img {
  display: block;
  width: 100%;
  height: auto;
}

.the-press .responsive-video-wrapper {
  margin-bottom: 70px;
}

.the-press .button.primary {
  background-color: #215ac2;
  color: white;
}

@media screen and (min-width: 480px) {
  .the-press .logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .the-press .logos .logo {
    width: 48%;
    max-width: 48%;
    flex: 0 0 48%;
    margin: 0 1%;
  }
}

@media screen and (min-width: 768px) {
  .the-press .logos .logo {
    width: 28%;
    max-width: 28%;
    flex: 0 0 28%;
    margin: 0 2.5% 40px 2.5%;
  }
}

@media screen and (min-width: 1025px) {
  .the-press {
    padding: 60px 0 60px 0;
  }

  .the-press h2 {
    font-size: 2.8em;
  }

  .the-press .featured {
    font-size: 1.75em;
  }
}

/*-----------------------
    Responsive Video Wrapper
-----------------------*/
.responsive-video-wrapper {
  max-width: 920px;
  margin: 0 auto 50px auto;
}

.responsive-video-wrapper .video-inner {
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.responsive-video-wrapper iframe {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

/*-----------------------
    Shop
-----------------------*/
.shop {
  color: white;
}

.shop h5 {
  font-size: 1.5em;
  line-height: 1.2;
  margin: 0 0 30px 0;
  font-family: 'Merienda One', cursive;
  letter-spacing: .2px;
}

.shop .reviews {
  background-color: #004c98;
}

.shop .reviews .social-wrapper,
.shop .reviews .ratings {
  padding: 20px;
  background-color: #215ac2
}

.shop .reviews .testimonials {
  padding: 20px;
  background-color: #004c98;
}

.shop .ratings .rating {
  margin-bottom: 25px;
}

.shop .ratings h6 {
  font-size: 1em;
  line-height: 1.4;
  margin: 0 0 8px 0;
  color: #deb025;
  font-weight: 600;
}

.shop .ratings p {
  font-size: 0.875em;
  line-height: 1.5;
  font-style: italic;
  color: white;
  margin: 0 0 8px 0;
}

.shop .ratings span.author {
  display: block;
  font-size: 0.875em;
  line-height: 1;
  margin: 0 0 8px 0;
}

.shop .ratings .star {
  display: inline-block;
  margin: 0 1px;
  font-size: 1.4em;
  color: #deb025;
}

.shop .view-more {
  display: inline-block;
  font-size: 1em;
  font-weight: 600;
  color: #deb025;
  border: 2px solid #deb025;
  padding: 7px 20px;
}

.shop .testimonial {
  text-align: right;
  margin-bottom: 40px;
}

.shop .testimonial p {
  font-size: 1em;
  line-height: 1.5;
  font-style: italic;
  color: white;
  margin: 0 0 20px 0;
}

.shop .testimonial span.author {
  display: block;
  font-size: 0.875em;
}

@media screen and (min-width: 1025px) {
  .shop .shop-content {
    display: flex;
  }

  .shop .shop-content .woocommerce-wrapper {
    flex: 1 1 500px;
  }

  .shop .shop-content .reviews {
    width: 500px;
    flex: 0 0 500px;
  }

  .shop .reviews .social-wrapper,
  .shop .reviews .ratings {
    padding: 30px;
  }

  .shop .reviews .ratings {
    padding-bottom: 50px;
  }

  .shop .reviews .testimonials {
    padding: 50px 30px 30px 30px;
  }
}

/*-----------------------
    Social
-----------------------*/
.social a {
  width: 40px;
  height: 40px;
  line-height: 41px;
  display: inline-block;
  font-size: 1.5em;
  background-color: #ddaf38;
  color: #004c98;
  border-radius: 50%;
  margin: 0 3px;
  text-align: center;
  transition: background-color 200ms linear;
}

.social a:hover {
  background-color: white;
}

/*-----------------------
    Contact Us
-----------------------*/
.contact-us {
  padding: 40px 0;
}

.contact-us .container {
  max-width: 660px;
  margin: 0 auto;
}

.contact-us h3 {
  font-size: 1.625em;
  font-weight: 400;
  color: #3e4780;
  margin: 0 0 25px 0;
}

.contact-us p {
  color: #3e4780;
  line-height: 1.5;
  font-weight: 500;
  margin: 0 0 30px 0;
}

.contact-us p span {
  font-weight: 700;
  display: block;
  color: #215ac2;
}

.contact-us .address {
  font-weight: 700;
  color: #215ac2;
  margin: 0 0 80px 0;
}

.contact-us h6 {
  font-size: 1.25em;
  color: #215ac2;
  font-weight: 600;
  margin: 0 0 30px 0;
}

.contact-us .contact-form .gfield {
  margin-bottom: 25px;
}

.contact-us .contact-form label {
  display: block;
  font-size: 0.8em;
  line-height: 1;
  color: #215ac2;
  margin: 0 0 8px 0;
}

.contact-us .contact-form input[type="text"],
.contact-us .contact-form input[type="email"],
.contact-us .contact-form textarea {
  display: block;
  width: 100%;
  border: 1px solid #215ac2;
  padding: 10px;
  font-size: 1em;
  line-height: 1;
  color: black;
}

.contact-us .contact-form .button {
  padding: 10px 20px;
  min-width: 170px;
  text-align: center;
  text-transform: none;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  border-color: #215ac2;
  background-color: white;
  color: #215ac2;
  cursor: pointer;
  transition: background-color 200ms linear, color 200ms linear;
}

.contact-us .contact-form .button:hover {
  background-color: #215ac2;
  color: white;
}

@media screen and (min-width: 768px) {
  .contact-us .contact-form input[type="text"],
  .contact-us .contact-form input[type="email"] {
    width: 305px;
  }

  .contact-us .gform_fields {
    position: relative;
  }

  .contact-us .contact-form .gfield:nth-child(2) {
    position: absolute;
    right: 0;
    top: 0;
  }
}

@media screen and (min-width: 1025px) {
  .contact-us {
    padding: 80px 0 70px 0;
    background: url('../images/waves-background.png');
  }
}

/*-----------------------
    Footer
-----------------------*/
footer {
  padding: 20px 0;
  background-color: #004c98;
  text-align: center;
  color: white;
}

footer p {
  font-size: 0.9em;
  line-height: 1.2;
  font-weight: 700;
  color: white;
  margin: 0 0 15px 0;
}

footer .company {
  margin-bottom: 30px;
}

footer .company .logo {
  display: inline-block;
  max-width: 200px;
  height: auto;
  margin-bottom: 30px;
}

footer .legal {
  margin-bottom: 20px;
}

footer .legal .logo {
  display: inline-block;
  width: 180px;
  height: auto;
  margin-bottom: 10px;
}

footer .navigation li {
  margin-bottom: 5px;
}

footer .navigation li a {
  font-weight: 500;
}

footer .navigation li a:hover {
  text-decoration: underline;
}

footer .social {
  margin-bottom: 20px;
}

footer .social a {
  margin: 0 3px;
}

footer .copy {
  padding-top: 15px;
}

footer .copy p {
  margin: 0;
}

@media screen and (min-width: 768px) {
  footer .information {
    display: flex;
    justify-content: space-between;
  }

  footer .company {
    flex: 0 0 200px;
    padding-top: 40px;
    margin: 0;
  }

  footer .legal {
    flex: 0 0 200px;
    margin: 0;
    transform: translateY(-35px);
  }

  footer .navigation {
    flex: 0 0 220px;
    text-align: left;
    padding-top: 40px;
  }

  footer .navigation li {
    margin-bottom: 2px;
  }

  footer .copy {
    padding-top: 0;
  }
}

/*-----------------------
    Buttons
-----------------------*/
.button {
  display: inline-block;
  font-size: 1em;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  border: 3px solid black;
  padding: 14px 25px;
  letter-spacing: 0.2px;
  transition: background-color 250ms linear, color 250ms linear;
  font-family: 'Raleway', sans-serif;
}

.button.primary {
  color: #215ac2;
  border-color: #215ac2;
}

.button.primary:hover {
  background-color: #215ac2;
  color: white;
}

.button.secondary {
  border-color: #d4a638;
  color: #d4a638;
  background-color: white;
  padding: 14px 30px;
}

.button.secondary:hover {
  color: white;
  background-color: #d4a638;
}

@media screen and (min-width: 1025px) {
  .button {
    font-size: 1.2em;
    padding: 14px 50px;
  }
}

/*-----------------------
    Helper classes
-----------------------*/
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both
}

img {
  border: medium none;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}

.img-center {
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 420px) {}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 1200px) {}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 991px),
only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 991px),
only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 991px),
only screen and (min-device-pixel-ratio: 2) and (max-width: 991px),
only screen and (min-resolution: 192dpi) and (max-width: 991px),
only screen and (min-resolution: 2dppx) and (max-width: 991px) {}